import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer-new';
import Modal from 'new/components/Modal';
import { history } from 'app';
import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import Cookies from 'universal-cookie';
import IconFontAwesome from 'new/components/IconFontAwesome';
import Button from 'new/components/Button/Button';
import findRoute from 'new/routing/routes';
import { formatDate } from 'new/utils/date';

const cookies = new Cookies();

export const appRoutes = [
  findRoute('SingleSetupPage'),
  findRoute('MultiSetupPage'),
];

const ModalContent = styled(Box)(({ theme }) => ({
  width: 500,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontFamily: 'Barlow',
  color: theme.palette.grey[800],

  '.title': {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 'bold',
  },

  button: {
    width: 200,
  },
}));

const TIME_TO_LOGOUT_MS = 1000 * 300; // 5 minutes
const IDLE_TIME_MS = 1000 * 60 * 15; // 15 minutes

const IdleModal: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  const isProd =
    window.location.href.includes('qa-client') ||
    window.location.href.includes('dev-client') ||
    window.location.href.includes('localhost');
  const formattedTime = formatDate(timeLeft, { format: 'm:ss' });

  const handleIdle = () => {
    const pathname = history.getCurrentLocation().pathname;
    const isNewAppRoute =
      appRoutes.includes(pathname || '') || pathname.includes('/account/');

    if (isNewAppRoute) {
      setOpen(true);
    }
  };

  const onActive = () => {
    setOpen(false);
    setTimeLeft(0);
  };

  const handleLogout = () => {
    const isSuperAdmin = cookies.get('beccascookie');

    if (isSuperAdmin) {
      localStorage.setItem('auth_token', cookies.get('beccascookie'));
      cookies.remove('associationId', { path: '/' });
      cookies.remove('beccascookie', { path: '/' });
      setTimeout(() => {
        handleClose();
        history.push('/admin/dentists');
      }, 250);
    } else {
      handleClose();
      history.push(findRoute('LoginPage').path);
    }
  };

  const onPrompt = () => {
    const pathname = history.getCurrentLocation().pathname;
    const isNewAppRoute = appRoutes.includes(pathname || '');

    if (isNewAppRoute) {
      setOpen(true);
      setTimeLeft(TIME_TO_LOGOUT_MS / 1000);
    }
  };

  const { isPrompted, getRemainingTime, activate } = useIdleTimer({
    timeout: IDLE_TIME_MS,
    onIdle: () => {
      if (!isProd) {
        handleIdle();
      }
    },
    promptTimeout: TIME_TO_LOGOUT_MS,
    onPrompt,
    onActive,
    crossTab: true,
  });

  const handleClose = () => {
    setOpen(false);
    activate();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        if (getRemainingTime() < 1000) {
          handleLogout();
        }

        setTimeLeft(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContent>
        <Box className="title">ALL DONE FOR NOW?</Box>
        <Box mt={3} mb={3}>
          <IconFontAwesome
            name="faExclamationTriangleReg"
            color="warning"
            size="5x"
          />
        </Box>
        <Box width={320} textAlign="center">
          <Box mb={1}>
            To ensure the security of your account, you will automatically be
            logged out in 5 minutes.
          </Box>
          Click “Continue Session” to keep going. Otherwise, log out and we’ll
          see you next time.
        </Box>
        {timeLeft && (
          <Box mt={2} color="error.main" fontWeight="bold">
            Time left: {formattedTime}
          </Box>
        )}
        <Stack direction="row" spacing={5} mt={4}>
          <Button color="error" onClick={handleLogout}>
            LOG OUT
          </Button>
          <Button onClick={handleClose}>CONTINUE SESSION</Button>
        </Stack>
      </ModalContent>
    </Modal>
  );
};

export default IdleModal;
