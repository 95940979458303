import { find, propEq } from 'ramda';
import { NamedRoute } from 'new/routing/types';
import { RouteName, routes } from 'new/routing/routesList';

const findRoute = (name: RouteName): NamedRoute => {
  const withName = propEq('name', name);
  const route = find<NamedRoute>(withName)(routes);

  if (!route) {
    throw new Error(`There's no Route with name < ${name} > defined in app`);
  }

  return route;
};

export default findRoute;
