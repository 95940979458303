const setFormatter = (maximumFractionDigits: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits,
  });

const setFormatterNoCurrency = (maximumFractionDigits: number) =>
  new Intl.NumberFormat('en-US', {
    maximumFractionDigits,
  });

export const formatNumberAsCurrency = (
  value: number,
  maximumFractionDigits = 2,
  shouldDisplayFractionDigits = false,
  hideCurrencySymbol = false,
) => {
  let customMaximumFractionDigits = maximumFractionDigits;
  const isWholeNumber = value % 1 === 0;

  if (isWholeNumber) {
    customMaximumFractionDigits = shouldDisplayFractionDigits
      ? maximumFractionDigits
      : 0;
  }
  const formatter = hideCurrencySymbol ? setFormatterNoCurrency : setFormatter;
  return formatter(customMaximumFractionDigits).format(value);
};

export const formatPhoneNumber = (formattedNumber: string) => {
  const onlyDigits = formattedNumber.replace(/\D/g, '');

  return onlyDigits.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'); //format 000-000-0000
};

export const getNumberWithOrdinal = (n: number) => {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};
