import { NamedRoute } from 'new/routing/types';

export type UserSettingsRouteName = 'UserSettings';

export const USER_SETTINGS_BASE_URL = '/account/settings/user';

export const USER_INFO_SUB_URL = 'info';
export const NOTIFICATION_SUB_URL = 'notifications';

export const userSettingsRoutes: NamedRoute[] = [
  {
    name: 'UserSettings',
    path: USER_SETTINGS_BASE_URL,
    getPath: (tab) => `${USER_SETTINGS_BASE_URL}${tab ? `?tab=${tab}` : ''}`,
  },
];
