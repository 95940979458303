import {
  differenceInSeconds,
  differenceInYears,
  format,
  fromUnixTime,
  intervalToDuration,
} from 'date-fns';
import moment from 'moment-timezone';

const COMMON_DATE_FORMAT = 'MM/dd/yyyy'; // duplicate because of jest tests

type GetEtTimezoneDateConfig = {
  isDateOnly?: boolean;
  isTimeOnly?: boolean;
  format?: string;
};

export const formatDate = (
  dateValue?: string | number | Date,
  config?: GetEtTimezoneDateConfig,
) => {
  const { isDateOnly, isTimeOnly, format: dateFormat } = config || {};
  let date;

  if (typeof dateValue === 'string' && dateValue.length) {
    date = new Date(dateValue);
  } else if (typeof dateValue === 'number') {
    date = fromUnixTime(dateValue);
  } else if (
    dateValue instanceof Date &&
    Object.prototype.toString.call(dateValue) === '[object Date]'
  ) {
    date = dateValue;
  } else {
    return '';
  }

  if (dateFormat?.length) {
    return format(date, dateFormat);
  } else if (isDateOnly) {
    return format(date, COMMON_DATE_FORMAT);
  } else if (isTimeOnly) {
    return format(date, 'hh:mm aaaaa') + 'm';
  } else {
    return format(date, `${COMMON_DATE_FORMAT} hh:mm aaaaa`) + 'm';
  }
};

type comparisonLevels = 'days' | 'hours' | 'minutes' | 'seconds';
const secondsIn1hour = 3600;
const secondsIn24hours = secondsIn1hour * 24;
const secondsIn30days = secondsIn24hours * 30;

export const countTimePassedTillNow = (date: string) => {
  const duration = intervalToDuration({
    start: new Date(date),
    end: new Date(),
  });
  const dateConfig = { isDateOnly: true, format: 'MM/dd/yy' };
  const difference = differenceInSeconds(new Date(), new Date(date));
  const checkIfPlural = (unit: comparisonLevels, number?: number) =>
    number + ' ' + (number === 1 ? unit.slice(0, -1) : unit) + ' ago';

  if (difference < 60) return difference + ' seconds ago';

  if (difference < secondsIn1hour) {
    return checkIfPlural('minutes', duration.minutes);
  }

  if (difference < secondsIn24hours) {
    return checkIfPlural('hours', duration.hours);
  }

  if (difference < secondsIn30days) {
    return checkIfPlural('days', duration.days);
  }

  return formatDate(date, dateConfig);
};

export const calculateAge = (dob: string) => {
  const date = new Date(dob);

  return differenceInYears(new Date(), date);
};

export const dateIsValid = (date: unknown) =>
  date instanceof Date && !isNaN(date as unknown as number);

export const isInLessThan30days = (date: string) => {
  if (!date) {
    return false;
  }

  const difference = differenceInSeconds(new Date(date), new Date());

  return difference > 0 && difference < secondsIn30days;
};

export const getLocalDateTime = (
  dateWithTime: string | number | Date,
  config?: GetEtTimezoneDateConfig,
) => {
  // const timezone = moment.tz.guess();
  moment.tz.setDefault('Europe/London');

  const dateFormat = config?.isTimeOnly ? '' : config?.format ?? 'MM/DD/YYYY';
  const timeFormat = config?.isDateOnly ? '' : 'HH:mm';

  let timezoneDateOnly = '';
  let timezoneTimeOnly = '';

  if (config?.isTimeOnly) {
    timezoneTimeOnly = moment
      .utc(dateWithTime)
      .tz('Europe/London')
      .format(timeFormat);
    const hour = parseInt(timezoneTimeOnly.split(':')[0]);
    timezoneTimeOnly += hour > 11 ? 'pm' : 'am';

    if (timezoneTimeOnly[0] === '0') {
      timezoneTimeOnly = timezoneTimeOnly.slice(1);
    }
  }

  if (config?.isDateOnly) {
    timezoneDateOnly = moment
      .utc(dateWithTime)
      .tz('Europe/London')
      .format(dateFormat);
  }

  return `${timezoneDateOnly} ${timezoneTimeOnly}`;
};
