import axios from 'axios';
import { getItem } from 'utils/localStorage';
import { errorHandler, errorHandlerNoToast } from './errorHandler';
import { successHandler, successHandlerNoToast } from './successHandler';

export const baseURL = '/api/v1/';

export const api = axios.create({
  baseURL,
  timeout: 0,
});

api.interceptors.request.use(
  (config) => {
    const authToken = getItem('auth_token');

    return {
      ...config,
      headers: {
        ...config?.headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(authToken && { Authorization: `JWT ${authToken}` }),
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(successHandler, errorHandler);


export const apiNoResponseMsg = axios.create({
  baseURL,
  timeout: 0,
});

apiNoResponseMsg.interceptors.request.use(
  (config) => {
    const authToken = getItem('auth_token');

    return {
      ...config,
      headers: {
        ...config?.headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(authToken && { Authorization: `JWT ${authToken}` }),
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiNoResponseMsg.interceptors.response.use(successHandlerNoToast, errorHandlerNoToast);