import { Tenant } from 'new/types/tenants';
import pngLogo from 'new/assets/logos/DHQ-ondark.png';
import pngLogoSmall from 'new/assets/logos/DHQ-small.png';
import pngLogoOnLight from 'new/assets/logos/DHQ-onlight.png';
import { ReactComponent as SvgLogo } from 'new/assets/logos/DHQ-ondark.svg';
import { ReactComponent as SvgLogoSmall } from 'new/assets/logos/DHQ-ondark-small.svg';
import { ReactComponent as SvgLogoOnLight } from 'new/assets/logos/DHQ-onlight.svg';

const dhq: Tenant = {
  id: 'dhq',
  name: 'DentalHQ',
  shortName: 'DHQ',
  nameSimple: 'Dental',
  supportEmail: 'support@dentalhq.com',
  successEmail: 'success@dentalhq.com',
  features: {
    setupBaa: true,
    gpp: true,
    leaderboardWidgetType: 'darkGreen',
    pms: true,
    csvSetup: false,
    payoutIssues: true,
    canDisableFamilyDiscounts: false,
  },
  constants: {
    launchRewards: {
      prize30: 50,
      prize60: 100,
      prize90: 150,
    },
    seasonOfGivingPrize: '$500, $250, and $125',
    marketingTemplates:
      'https://dentalhq-files.s3.amazonaws.com/Email+%26+Text+Blasts+to+Patients+(Template)+.docx',
  },
  elevio: {
    cancellations: '108',
    trackPayments: '25',
    pricing: '107',
  },
  logos: {
    pngLogo,
    pngLogoSmall,
    pngLogoOnLight,
    SvgLogo,
    SvgLogoSmall,
    SvgLogoOnLight,
  },
};

export default dhq;
