import { generatePath } from 'react-router-dom';
import { isNil } from 'ramda';
import {
  CreateEndpointArgs,
  CreateEndpointResult,
  CreateFetchEndpointArgs,
  CreateFetchEndpointResult,
} from './types';
import { baseURL } from './axios';
import { getItem } from 'utils/localStorage';

export const createEndpoint = <D, P = unknown>({
  endpoint,
  url,
}: Omit<CreateEndpointArgs<D, P>, 'queryKey'>): CreateEndpointResult<D, P> => {
  return {
    url,
    endpoint: (params, id) =>
      endpoint({
        url: isNil(id) ? url : generatePath(url, { id }),
        params,
        id,
      }),
  };
};

// only use if axios fails
// built to be used on beforeunload event to log close window event
export const createFetchEndpoint = <D, P = unknown>({
  endpoint,
  url,
}: Omit<CreateFetchEndpointArgs<D, P>, 'queryKey'>): CreateFetchEndpointResult<
  D,
  P
> => {
  return {
    url,
    endpoint: (params, id) =>
      endpoint({
        url: baseURL + (isNil(id) ? url : generatePath(url, { id })),
        params,
        id,
      }),
  };
};

export default function getFetchHeaders(headers?: HeadersInit) {
  const authToken = getItem('auth_token');
  return {
    ...headers,
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(authToken && { Authorization: `JWT ${authToken}` }),
  };
}
