/*
Account Security Form Modal Component
================================================================================
*/

/*
Import
------------------------------------------------------------
*/
// libs
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import Row from 'react-bootstrap/lib/Row';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import {
  Field,
  formValueSelector,
  reduxForm,
  submit as submitForm
} from 'redux-form';

// app
import Checkbox from 'components/Checkbox';
import LabeledInput from 'components/LabeledInput';
import LoadingSpinner from 'components/LoadingSpinner';
import { getItem } from '../../utils/localStorage';

// local
import styles from './styles.module.css';

/*
Member Form Modal
================================================================================
*/
@reduxForm({ form: 'varidiForm'})
@CSSModules(styles,{allowMultiple:true})
export default class AddLocationModal extends React.Component {

  static propTypes = {

    // modal related - passed in
    show: PropTypes.bool.isRequired,
   // onCancel: PropTypes.func.isRequired,
  };

  constructor (props) {
    super(props);

    this.state = {
      redirecting: false,
      confirmed: false,
    };
  }

  /*
  Render
  ------------------------------------------------------------
  */
  render () {
    const {
      // modal related
      show,
      onCancel,
      user,
      submitting,
    } = this.props;

    const {redirecting,confirmed} = this.state;

    return (
      <Modal
        backdrop={'static'}
        bsSize={'lg'}
        onHide={onCancel}
        show={show}
      >
        {/*
        Modal Header
        ------------------------------------------------------------
        */}
        <Modal.Header closeButton>
          <Modal.Title>ADDING LOCATIONS</Modal.Title>
        </Modal.Header>

        {/*
        Modal Body
        ------------------------------------------------------------
        */}
        <Modal.Body>
            {user && user.type=='dentist' && (
                        <div>

                  <div className="mbr-text col-12 col-md-12 mbr-fonts-style display-7" styleName="intro">Adding locations is easy, but does require that certain things happen in a certain order. If you've not added a location before, please contact us at <a href="mailto:support@dentalhq.com" target="_blank">support@dentalhq.com</a>, or call 919-829-7192.
                  <br/>
                      <br/>
        If you prefer live onscreen assistance, <a href="https://calendly.com/tkennedy-dhq/one-on-one-for-q-a-s" target="_blank">click here</a> to schedule a time at your convenience.  
        <br/><br/></div>
              <div  styleName="buttons">
                  <div className="media-container-row title">
                  <div className="col-8 col-md-8">
                          <div className="mbr-section-btn align-center"><a className="btn btn-primary display-4 btn-success" href="https://calendly.com/tkennedy-dhq/one-on-one-for-q-a-s" target="_blank" styleName="varidi-button">SCHEDULE A TIME</a></div>
                      </div>
                      <div className="col-4 col-md-4">
                          <div className="mbr-section-btn align-center"><form><a className="btn btn-primary display-4" href={user?"/api/v1/accounts/duplicate/"+user.firstOffice+"/"+user.userId:""}  styleName="varidi-button">ADD LOCATION NOW</a>{submitting && <LoadingSpinner showOnlyIcon />}</form></div>
                      </div>
                  </div>
              </div>
          </div>
        )}
                    {user && user.type=='office_admin' && (
                      <div>Contact us at  <a href="mailto:support@dentalhq.com" target="_blank">support@dentalhq.com</a> if you need additional locations.</div>
                    )}

        </Modal.Body>

        {/*
        Modal Footer
        ------------------------------------------------------------
        */}
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    );
  }
}
