import { NoteFormValues } from 'new/modules/MembersPage/types';

export const DEBOUNCE_SEARCH_TIMEOUT = 500;

export const SEARCH_FILTER_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'past_due', label: 'Late' },
  { value: 'inactive', label: 'Canceled' },
  { value: 'new', label: 'New (last 30 days)' },
  { value: 'renewal', label: 'Upcoming Renewals (30 days)' },
  { value: 'monthly', label: 'Members Paying Monthly' },
  { value: 'annual', label: 'Members Paying Annually' },
  // { value: 'autorenew', label: 'Auto-renew Members' },
  { value: 'nonautorenew', label: 'Non-auto-renew Members' },
  { value: 'inoffice', label: 'In-office Payment' },
  { value: 'delinquent', label: 'Delinquent' },
  { value: 'disputed', label: 'Disputed' },
  { value: 'business', label: 'BusinessCONNECT™ Members' },
  { value: 'community', label: 'CommunityCONNECT™ Members' },
  { value: 'notUnderContract', label: 'Members Not under contract' },
  { value: 'transferred', label: 'Imported Members' },
  { value: 'pmsNotConnected', label: 'Not connected to PMS' },
  { value: 'isGPP', label: 'Guaranteed Members' },
];

export const SEARCH_FILTER_OPTIONS_COMPULSORY = [
  'all',
  'active',
  'past_due',
  'inactive',
  'new',
  'monthly',
  'annual',
];

export const SEARCH_FILTER_OPTIONS_OPTIONAL = [
  'renewal',
  'nonautorenew',
  'inoffice',
  'delinquent',
  'disputed',
  'business',
  'community',
  'notUnderContract',
  'transferred',
  'pmsNotConnected',
  'isGPP'
];

export const CANCELATION_OPTIONS = [
  {
    value: '1',
    label: 'Insurance-Related: Has or is considering insurance',
  },
  {
    value: '2',
    label: 'Inactive Patient: Moved, changed offices, etc.',
  },
  {
    value: '3',
    label: 'Financial: Too expensive, payment issues, cannot afford, etc.',
  },
  {
    value: '5',
    label: 'Clerical / Administrative: Office error, duplicate entry, etc.',
  },
  {
    value: '6',
    label: 'Health-Related: Health issues or concerns (deceased)',
  },
  {
    value: '11',
    label: 'Office Request: Patient dismissed, patient now employee, etc.',
  },
  {
    value: '12',
    label: 'Patient Request: No reason provided, "it\'s complicated", etc.',
  },
];

export const NOTE_TYPE_OPTIONS = [
  {
    value: 'billing',
    label: 'Billing',
  },
  {
    value: 'member',
    label: 'Member',
  },
  {
    value: 'plan',
    label: 'Plan',
  },
];

export const initialNoteFormValues: NoteFormValues = {
  type: '',
  description: '',
};

export const PLAN_OPTIONS = [
  {
    value: '1',
    label: 'pl1',
  },
  {
    value: '2',
    label: 'pl2',
  },
];
