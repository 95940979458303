/*
Account Security Form Modal Component
================================================================================
*/

/*
Import
------------------------------------------------------------
*/
// libs
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import Row from 'react-bootstrap/lib/Row';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import {
  Field,
  formValueSelector,
  reduxForm,
  submit as submitForm
} from 'redux-form';

// app
import Checkbox from 'components/Checkbox';
import LabeledInput from 'components/LabeledInput';
import LoadingSpinner from 'components/LoadingSpinner';

import { getItem } from '../../utils/localStorage';

// local
import styles from './styles.module.css';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';


let disabledtooltip = <Tooltip id="tooltip">This feature is currently disabled. Please contact DentalHQ if you have any questions.</Tooltip>;
/*
Member Form Modal
================================================================================
*/
@reduxForm({ form: 'varidiForm'})
@CSSModules(styles,{allowMultiple:true})
export default class PaymentPlanModal extends React.Component {

  static propTypes = {

    // modal related - passed in
    show: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onActivate: PropTypes.func,
  };

  constructor (props) {
    super(props);

    this.state = {
      redirecting: false,
      confirmed: false,
    };
  }

  varidiSetup = () => {
     this.props.onActivate();
     this.setState({
        confirmed: true,
    });
  }

  gargleVaridi = () => {
    this.props.gargleVaridi();
    this.setState({
       confirmed: true,
   });
 }

  varidiDemo = () => {
    this.setState({
        redirecting: true,
     });
    window.location = `https://calendly.com/dentalhqvaridi`;
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.dentist&& this.props.dentist.dentistVaridi){
        this.setState({
            redirecting: true,
        });
      if(nextProps.show==true){
         window.open(`/api/v1/varidi-connect/login/${this.props.dentist.dentistInfo.id}/${this.props.user.id}`);
         this.props.onCancel();
      }
    }
  }
  /*
  Render
  ------------------------------------------------------------
  */
  render () {
    const {
      // modal related
      show,
      onCancel,
      onActivate,
      dentist,
      submitting,
    } = this.props;

    const {redirecting,confirmed} = this.state;

    return (
      <Modal
        backdrop={'static'}
        bsSize={'lg'}
        onHide={onCancel}
        show={show}
      >
        {/*
        Modal Header
        ------------------------------------------------------------
        */}
        <Modal.Header closeButton>
          <Modal.Title>IN-HOUSE GUARANTEED PAYMENT PLANS</Modal.Title>
        </Modal.Header>

        {/*
        Modal Body
        ------------------------------------------------------------
        */}
        <Modal.Body>
            {dentist && !dentist.dentistVaridi &&!confirmed && (
                <div>
                  <div styleName="subtitle">
              Never deny a patient financing again.
            </div>
            <div styleName="text">
              Do you enjoy telling a patient they've been denied financing? Of
              course not.
              <br /> That’s why we teamed up with <b>Varidi</b>, the leader in
              flexible direct-to-consumer financing, to bring you this amazing
              partner benefit. 
              <br/>In-house financing plus your in-house membership plan.
              Perfect.
              <br/>
              &emsp;
            </div>
            <div styleName="subtitle">Why this is a really really really good idea:</div>
                   <div className="mbr-text counter-container col-12 col-md-12 mbr-fonts-style display-7" styleName="explanation">
              <ul>
              <li><strong>Process: Simplified</strong><br/>  With Varidi Financing™, you submit{' '}
                  <span styleName="highlightText">one application</span>, and in
                  a matter of seconds your client is approved. No credit check
                  required.</li>
                  <li><strong>Approval: Guaranteed<br/></strong>If someone doesn’t qualify for Varidi Financing™, fret not –
                  <span styleName="highlightText">everyone’s approved</span> for
                  Varidi Payment Guarantee, which also ensures you get paid even
                  if your patient stops paying.</li>
                  <li><strong>Profit: Maximized<br/></strong>When you have a reliable payment solution available for
                  everyone, fewer patients decline treatment, meaning{' '}
                  <span styleName="highlightText">
                    more patients accept treatment
                  </span>
                  . When more patients accept treatment, revenue goes up. Boom.</li>
                  
              </ul>
          </div>

      <div  styleName="buttons">
          <div className="media-container-row title">

          <div className="col-4 col-md-4">
                  <div className="mbr-section-btn align-center"><a className="btn btn-primary display-4 btn-success" href="https://calendly.com/dentalhqvaridi" target="_blank" styleName="varidi-button">SCHEDULE A DEMO</a></div>
              </div>
              <div className="col-4 col-md-4">
                  {this.props.dentist!=undefined&&this.props.dentist.dentistInfo.associationId==3&&(
                  <div className="mbr-section-btn align-center"><form><a className="btn btn-primary display-4" onClick={this.gargleVaridi}  styleName="varidi-button">I HAVE A VARIDI ACCOUNT</a>{submitting && <LoadingSpinner showOnlyIcon />}</form></div>
                  )}
                  </div>
              <div className="col-4 col-md-4">
                  <div className="mbr-section-btn align-center"><form><a className="btn btn-primary display-4" onClick={this.varidiSetup}  styleName="varidi-button">ACTIVATE YOUR ACCOUNT</a>{submitting && <LoadingSpinner showOnlyIcon />}</form></div>
              </div>
          </div>
      </div>
  </div>
)}
    {redirecting &&!confirmed &&
    <div>
        Your Varidi Dashboard will open in a new window<LoadingSpinner showOnlyIcon />
    </div>
    }
    {submitting &&
    <div>
        <strong>Activating</strong> <LoadingSpinner showOnlyIcon />
    </div>
    }
    {confirmed &&!submitting &&
    <div>
        DentalHQ has partnered with Varidi, Inc. to provide you with guaranteed payment plans. <br/><br/>Your account information has been securely sent to Varidi, and you will receive a confirmation email momentarily. <strong>Click the link in the email</strong> to complete your account setup.
    </div>
    }

<br/>

        </Modal.Body>

        {/*
        Modal Footer
        ------------------------------------------------------------
        */}
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    );
  }
}
