import enUS from '../../translations/enUS.json';
import esUS from '../../translations/esUS.json';
import { Locale } from './types';

export const defaultLocale: Locale = 'en-US';

export const locales: Record<Locale, { messages: Record<string, string> }> = {
  'en-US': {
    messages: enUS,
  },
  'es-US': {
    messages: esUS,
  },
};
