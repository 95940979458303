import { NamedRoute } from '../../routing/types';

export type PlanBuilderRoutesName = 'PlanBuilderPage';

export const PLAN_BUILDER_BASE_URL = '/plan-builder';

export const planBuilderRoutes: NamedRoute[] = [
  {
    name: 'PlanBuilderPage',
    path: `${PLAN_BUILDER_BASE_URL}`,
    getPath: () => PLAN_BUILDER_BASE_URL,
  },
];
