import { TenantsData } from 'new/types/tenants';
import dhq from './config/dhq';
import vhq from './config/vhq';

const tenantsData: TenantsData = {
  dhq,
  vhq,
};

const getTenantId = () => {
  const dhqPatterns = ['dentalhq.com', 'dentalhq-dev', 'dentalhq-qa'];
  const vhqPatterns = ['vision-hq.com', 'vision-dev', 'vision-qa'];

  if (
    process.env.REACT_APP_TENANT == 'dhq' ||
    dhqPatterns.some((key) => window.location.href.includes(key))
  ) {
    return 'dhq';
  }
  if (
    process.env.REACT_APP_TENANT == 'vhq' ||
    vhqPatterns.some((key) => window.location.href.includes(key))
  ) {
    return 'vhq';
  }
  return 'dhq';
};

export const TENANT = tenantsData[getTenantId()];
