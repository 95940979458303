import { NamedRoute } from 'new/routing/types';

export type UpgradePlanRouteName = 'UpgradePlan';

export const UPGRADE_PLAN_BASE_URL = '/account/upgrade';

export const upgradePlanRoutes: NamedRoute[] = [
  {
    name: 'UpgradePlan',
    path: UPGRADE_PLAN_BASE_URL,
    getPath: (dentistInfoId) =>
      `${UPGRADE_PLAN_BASE_URL}?dentistInfoId=${dentistInfoId}`,
  },
];
