import * as React from 'react';
import { Link } from 'react-router';
import { SxProps, Theme, styled } from '@mui/material/styles';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const StyledLink = styled(Link)({
  '&:hover': {
    textDecoration: 'none',
  },
});

const StyledButton = styled(MuiButton)({
  minWidth: 134,
  height: 52,
  borderRadius: 7,
  fontWeight: 'bold',
  borderWidth: 2,
  fontFamily: 'Barlow Semi Condensed',
  boxShadow: '0 3px 6px 0 rgba(0,0,0,0.16)',
  textTransform: 'uppercase',

  '&.MuiButton-root': {
    fontSize: 20,
  },
  '&.MuiButton-root:hover': {
    borderWidth: 2,
  },
});

export type ButtonProps = MuiButtonProps & {
  isLoading?: boolean;
  to?: string;
  target?: string;
  sx?: SxProps<Theme>;
};

const Button: React.FC<ButtonProps> = ({
  children,
  color = 'primary',
  variant = 'contained',
  disabled,
  startIcon: startIconProp,
  isLoading,
  to,
  target,
  ...props
}) => {
  const startIcon = isLoading ? (
    <CircularProgress color="secondary" size={20} />
  ) : (
    startIconProp
  );

  const button = (
    <StyledButton
      color={color}
      variant={variant}
      disabled={disabled || isLoading}
      startIcon={startIcon}
      {...props}
    >
      {children}
    </StyledButton>
  );

  if (to) {
    return (
      <StyledLink to={to} target={target}>
        {button}
      </StyledLink>
    );
  }

  return button;
};

export default Button;
