import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Paper, Typography } from '@mui/material';
import MuiModal from '@mui/material/Modal';
import { ModalProps } from '@mui/material/Modal/Modal';
import { SxProps, Theme, styled } from '@mui/material/styles';
import { ErrorConfigProps } from 'new/components/ModalContent/ErrorModalContent';
import ModalFinalView from 'new/components/ModalContent/ModalFinalView';
import { SuccessConfigProps } from 'new/components/ModalContent/SuccessModalContent';
import React, { ReactNode } from 'react';

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isBarTitle',
})<{ isBarTitle?: boolean }>(({ theme, isBarTitle }) => ({
  maxWidth: '95%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: theme.spacing(5),
  paddingTop: theme.spacing(isBarTitle ? 9 : 5),
  outline: 'none',
  overflow: 'hidden',

  '> button svg': {
    color: theme.palette.grey[400],
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  position: 'absolute',
  top: 10,
  right: 4,
  zIndex: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const CardModalTitle = styled(Typography)({
  fontFamily: 'Barlow',
  fontSize: '1.25rem',
  fontWeight: 'bold',
  textAlign: 'center',
});

const CardModalBarTitle = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: 60,
  background: theme.palette.primary.dark,
  lineHeight: '60px',
  color: '#fff',
  fontSize: '1.375rem',
  fontWeight: 500,
  textAlign: 'center',
  zIndex: -1,
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
}));

type Props = Omit<ModalProps, 'children' | 'title'> & {
  open: boolean;
  onClose?: () => void;
  title?: ReactNode;
  barTitle?: ReactNode;
  isSuccess?: boolean;
  isError?: boolean;
  successConfig?: SuccessConfigProps;
  errorConfig?: ErrorConfigProps;
  sx?: SxProps<Theme>;
};

const Modal: React.FC<Props> = ({
  children,
  onClose,
  title,
  barTitle,
  isSuccess = false,
  isError = false,
  successConfig,
  errorConfig,
  sx,
  ...props
}) => {
  let content = (
    <>
      {onClose && (
        <CloseButton
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          <CloseIcon />
        </CloseButton>
      )}
      {barTitle && <CardModalBarTitle>{barTitle}</CardModalBarTitle>}
      {title && <CardModalTitle className="title">{title}</CardModalTitle>}
      {children}
    </>
  );

  if (isSuccess || isError) {
    content = (
      <ModalFinalView
        isSuccess={isSuccess}
        isError={isError}
        successConfig={successConfig}
        errorConfig={errorConfig}
      />
    );
  }

  return (
    <MuiModal {...props} onClose={onClose}>
      <StyledPaper
        className="modalContent"
        isBarTitle={!!barTitle}
        elevation={3}
        sx={sx}
      >
        {content}
      </StyledPaper>
    </MuiModal>
  );
};

export default Modal;
