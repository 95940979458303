/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
//import 'babel-polyfill';
/* eslint-disable import/no-webpack-loader-syntax */
// Load the manifest.json file and the .htaccess file
import 'file-loader?name=[name].[ext]!./.htaccess';
import 'file-loader?name=[name].[ext]!./assets/robots.txt';
import 'file-loader?name=[name].[ext]!./assets/favicon-new2.ico';
import '!file-loader?name=hq-email-signature/[name].[ext]!./assets/images/hq-email-sig-logo.png';


/* eslint-enable import/no-unresolved */

// Import all the third party stuff
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { applyRouterMiddleware, browserHistory, Router } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
//import useScroll from 'react-router-scroll';
import getHooks from 'utils/hooks';

import App from 'containers/App';
// Load base styles
import 'sanitize.css/sanitize.css';
import 'bootstrap-css-only/css/bootstrap.min.css';

import 'assets/styles/app.module.css';

import configureStore from './store';
import createRoutes from './routes';

import NewProviders from './new/components/Providers'

/* eslint-enable import/no-unresolved */

// Create redux store with history
// this uses the singleton browserHistory provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const browserHistory = useRouterHistory(createBrowserHistory)();`
const store = configureStore();
export const history = syncHistoryWithStore(browserHistory, store);

// Set up the router, wrapping all Routes in the App component
const rootRoute = {
  childRoutes: createRoutes(store),
  getComponent (nextState, cb) {
    // We need to inject sagas after loaded.
    Promise.all([
      import('containers/App/sagas'),
    ])
      .then(([ sagas ]) => {
        cb(null, App);
        const { injectSagas } = getHooks(store);
        // Each of these sagas needs to be cancelled upon location change
        // Otherwise there will be many duplicates!
        injectSagas(sagas.default);
      });
  }
};

// Scroll to top when going to a new page, imitating default browser behavior
// function scrollMiddleware () {
//   return useScroll((prevProps, props) => {
//     if (!prevProps || !props) {
//       return true;
//     }

//     if (prevProps.location.pathname !== props.location.pathname) {
//       return [ 0, 0 ];
//     }

//     return true;
//   });
// }

// Extend String class add ucFirst method
String.prototype.ucFirst = function () {
  return this[ 0 ] ? `${this[ 0 ].toUpperCase()}${this.substr(1)}` : this;
};

render(
  <NewProviders>
  <Provider store={store}>
    <div>
      <Router
        history={history}
        routes={rootRoute}
        //render={applyRouterMiddleware(useScroll())}
        render={applyRouterMiddleware()}
      />

    </div>
  </Provider>
  </NewProviders>,
  document.getElementById('app')
);


// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed

// const install = require('offline-plugin/runtime').install;

// install();

// Code to uninstall service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations()
    .then(registrations => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  caches.keys()
    .then(keyList => {
      return Promise.all(keyList.map(key => {
        return caches.delete(key);
      }));
    });
}

