/*
Account Security Form Modal Component
================================================================================
*/

/*
Import
------------------------------------------------------------
*/
// libs
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import Row from 'react-bootstrap/lib/Row';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import {
  Field,
  formValueSelector,
  reduxForm,
  submit as submitForm
} from 'redux-form';

// app
import Checkbox from 'components/Checkbox';
import LabeledInput from 'components/LabeledInput';
import LoadingSpinner from 'components/LoadingSpinner';
import { getItem } from '../../utils/localStorage';

// local
import styles from './styles.module.css';

/*
Member Form Modal
================================================================================
*/
@reduxForm({ form: 'elevateForm'})
@CSSModules(styles,{allowMultiple:true})
export default class PaymentPlanModal extends React.Component {

  static propTypes = {

    // modal related - passed in
    show: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onActivate: PropTypes.func,
  };

  constructor (props) {
    super(props);

    this.state = {
      redirecting: false,
      confirmed: false,
    };
  }

  elevateSetup = () => {
     this.props.onActivate();
     this.setState({
        confirmed: true,
    });
  }

  elevateDemo = () => {
    this.setState({
        redirecting: true,
     });
    window.location = `https://elevatebillingsolutions.com/dental-hq/`;
  }

  /*
  Render
  ------------------------------------------------------------
  */
  render () {
    const {
      // modal related
      show,
      onCancel,
      onActivate,
      dentist,
      submitting,
    } = this.props;

    const {redirecting,confirmed} = this.state;

    return (
      <Modal
        backdrop={'static'}
        bsSize={'lg'}
        onHide={onCancel}
        show={show}
      >
        {/*
        Modal Header
        ------------------------------------------------------------
        */}
        <Modal.Header closeButton>
          <Modal.Title>REMOTE BILLING</Modal.Title>
        </Modal.Header>

        {/*
        Modal Body
        ------------------------------------------------------------
        */}
        <Modal.Body>
            {dentist && (
                <div>

          <div className="mbr-text col-12 col-md-12 mbr-fonts-style display-7" styleName="intro">Through our partnership with Elevate Billing Solutions, we can provide your practice with insurance claim and billing cycle management. Your practice is assigned one Billing Specialist who has remote access to your dental software, manages your insurance claims cycle, and acts as an extension of your team so that you can spend more time focusing on your patients and growing your practice.</div>

          <div className="mbr-text counter-container col-12 col-md-12 mbr-fonts-style display-7" styleName="explanation">
              <ol>
                  <li><strong>Electronic claims sent with attachments and narratives.<br/></strong>Each of our billers has 10+ years of dental billing experience and a proven history of collecting 99% or higher.</li>
                  <li><strong>Denied claims and outstanding A/R worked and resubmitted<br/></strong>Reduce and manage your outstanding insurance A/R and improve practice cash flow.</li>
                  <li><strong>Retrieval and reconciliation of EOBs and EFTs.<br/></strong>Accurate posting of payments into your dental software daily. No more delayed accounts!</li>
              </ol>
          </div>

      <div  styleName="buttons">
          <div className="media-container-row title">
          <div className="col-12 col-md-12">
                  <div className="mbr-section-btn align-center"><a className="btn btn-primary display-4 btn-success" target="_blank" href="https://elevatebillingsolutions.com/dental-hq/" styleName="elevate-button">SCHEDULE A DEMO</a></div>
              </div>
          </div>
      </div>
  </div>
)}
    {redirecting &&!confirmed &&
    <div>
        You will be redirected to our partner, Elevate Billing Solutions, in a new window<LoadingSpinner showOnlyIcon />
    </div>
    }
    {submitting &&
    <div>
        <strong>Activating</strong> <LoadingSpinner showOnlyIcon />
    </div>
    }
    {confirmed &&!submitting &&
    <div>

    </div>
    }

<br/>

        </Modal.Body>

        {/*
        Modal Footer
        ------------------------------------------------------------
        */}
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    );
  }
}
